import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './admin.css';

const Admin = () => {
    const { id } = useParams();
    const [quote, setQuote] = useState(null);
    const [amount, setAmount] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [message, setMessage] = useState('')
    useEffect(() => {
        const fetchQuote = async () => {
            try {
                const response = await axios.get(`https://api.byebyejunk.co/quotes/${id}`);
                setQuote(response.data);
                if (response.data.date) setDate(response.data.date);
                if (response.data.time) setTime(response.data.time);
            } catch (error) {
                console.error("Error fetching the quote:", error);
            }
        };

        fetchQuote();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`https://api.byebyejunk.co/quotes/${id}`, { amount, date, time });
            await axios.post('https://api.byebyejunk.co/pays/send-payment-email', {
                clientEmail: quote.email,
                clientName: quote.name,
                amount: amount,
                date: date,
                time: time,
                id: id,
                message: message
            });
            alert('Email sent successfully');
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Failed to send email');
        }
    };

    return (
        <div className='admin-container'>
            <h1>Admins Only</h1>
            {quote && (
                <div>
                    <form onSubmit={handleSubmit}>
                        <input
                            type='number'
                            placeholder="Amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            required
                        />
                        <input
                            type='date'
                            placeholder='Date'
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            required
                        />
                        <input
                            type='time'
                            placeholder='Time'
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                            required
                        />
                        <textarea
                            id="message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <input type="hidden" id="quoteId" name="quoteId" value={id} />
                        <button type="submit">Send Email</button>
                    </form>
                    <div>
                        <p>Name: {quote.name}</p>
                        <p>Email: {quote.email}</p>
                        <p>Address: {quote.address}</p>
                        <p>Date: {quote.date}</p>
                        <p>Time: {quote.time}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

const AdminPasswordProtected = () => {
    const [password, setPassword] = useState('');
    const [isAuthorized, setIsAuthorized] = useState(false);

    const correctPassword = 'Griffensjunkremoval123##'; // Replace with your desired password

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (password === correctPassword) {
            setIsAuthorized(true);
        } else {
            alert('Incorrect password!');
        }

    };

    return (
        <div>
            {!isAuthorized ? (
                <form onSubmit={handlePasswordSubmit}>
                    <label htmlFor="password">Enter Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button type="submit">Submit</button>
                </form>
            ) : (
                <Admin />
            )}
        </div>
    );
};

export default AdminPasswordProtected;
