import React from 'react';
import './footer.css'
import LOGO from './WeDumpJunk Final logo.png'
const Footer = () => {


  return (
    <footer class='footer'>
    <div class='footer-content'>
      <div class='footer-header-container'>
        <h3 class='footer-header'>Bye Bye Junk</h3>
        <p>This website provides professional junk removal services for Land O' Lakes and nearby areas.
          From furniture and appliance removal to yard waste and general household junk, we offer efficient solutions.</p>
          <img class="footer-logo" src={LOGO} alt="Logo"></img>
      </div>
      <div class='footer-columns'>
        <div class='footer-col'>
          <h4 class='footer-col-header'><b>Links</b></h4>
          <ul class="footer-links">
            <li><a href="/" class="footer-link">Home</a></li>
            <li><a href='/quote' class="footer-link">Quote</a></li>
            <li><a href="/contact" class="footer-link">Contact</a></li>
          </ul>
        </div>
        <div class='footer-col'>
          <h4 class='footer-col-header'><b>Services</b></h4>
          <ul class="footer-links">
            <li class='footer2-links'>Furniture removal</li>
            <li class='footer2-links'>Appliance disposal</li>
            <li class='footer2-links'>Yard waste and garden debris</li>
            <li class='footer2-links'>General household junk</li>
          </ul>
        </div>
        <div class='footer-col'>
          <h4 class='footer-col-header'><b>Contact</b></h4>
          <ul class="footer-links">
            <li class='footer3-links'> (813) 459-5731</li>
            <li class='footer3-links'>
            <a href="mailto:byebyejunkremovalservice@gmail.com">byebyejunkremovalservice@gmail.com</a>
            </li>
            <li class='footer3-links'> Serving Land O Lakes and surrounding areas</li>
            <li class='footer3-links'>Follow Us: [Social Media Links]</li>
          </ul>
        </div>
      </div>
    </div>
  </footer>

  )
}

export default Footer;