import { Link } from 'react-router-dom';
import './nav.css'
import LOGO from './WeDumpJunk Final logo.png'

const Nav = () => {

  return (


    <div className="custom-nav-container">


      <div className="custom-nav-content">
        <a href="/">
          <img className="logo" src={LOGO} alt="Logo" />
        </a>
      </div>
      <nav>
        <ul className="custom-nav-links" id="navLinks">
          <li className="custom-nav-links-home">
            <Link className="custom-nav-link" to="/">Home</Link>
          </li>
          <li className="custom-nav-links">
            <Link className="custom-nav-link" to="/contact">Contact Us</Link>
          </li>
          <li className="custom-nav-item-quote">
            <Link className="custom-nav-link-quote" to="/quote">Quote</Link>
          </li>
        </ul>
      </nav>

    </div>


  )
}

export default Nav;