import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Quote from './pages/Quote';
import Admin from './pages/Admin';
import Pay from './pages/Pay'



function App() {
  return (
    <Router>
      <Routes>
        <Route path='/contact' element={<Contact/>}/>
        <Route path="/" element={<Home />} />
        <Route path="/quote" element={<Quote />} />
        <Route path="/admin/:id" element={<Admin />} />
        <Route path="/pay/:id" element={<Pay />} />
      </Routes>
    </Router>
  );
}

export default App;
