import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './home.css'; // Import your custom CSS
import Footer from '../components/Footer'; // Assuming you have a Footer component
import Nav from '../components/Nav';
import aboutGif from './IMG_8982.jpeg';


const Home = () => {
  return (
    <>
      {/* Desktop Version - Visible only on large screens */}
      <div >
        <div className="home-container">
          <Nav />


          <h1 className="h1-home">Local Junk Removal</h1>
          <h2 className="home-h2">Serving Land O Lakes and Surrounding Areas</h2>
          <div className="home-action-button-container">
            <Link to="/quote">
              <button className="home-button">Get Your Free Quote!</button>
            </Link>
          </div>
          <div className="test-label">
            <h3 className="home-test-font">Testimonial</h3>
          </div>
        </div>

        {/* Testimonials Section */}
        <div className="testimonal-container">
          <div className="home-card-1">
            <h4 className="home-card-header1">Fast, Efficient, and Professional Service!</h4>
            <p className="test-p1">
              "I had a large amount of junk cluttering my garage for months.
              The team arrived on time, quickly removed everything, and left the space spotless.
              They were polite and professional throughout.
              Highly recommend their services!"
            </p>
            <footer className="testimonial-author">- Jessica Taylor</footer>
          </div>

          <div className="home-card-2">
            <h4 className="home-card-header2">Reliable and<br />Affordable!</h4>
            <p className="test-p2">
              I needed help with removing old furniture and appliances from my rental property.
              The pricing was clear and affordable, and they completed the job much faster than I expected.
              This is my go-to junk removal company now.
            </p>
            <footer className="testimonial-author">- Skylar Morgan</footer>
          </div>

          <div className="home-card-3">
            <h4 className="home-card-header3">Eco-Friendly and Hassle-Free!</h4>
            <p className="test-p3">
              I was worried about how to dispose of my old electronics and bulky items responsibly.
              This service made it easy—they recycled what they could and ensured proper disposal of everything else.
              It's great to know they care about the environment!
            </p>
            <footer className="testimonial-author">- Logan Bennett</footer>
          </div>
        </div>

        {/* About Me Section */}

        <div className="about-me">
          <div className="about-img-container">
            <img className="about-img" alt="about-image" src={aboutGif} />
          </div>
          <div className="about-p-container">
            <h4 className="about-header">About ME</h4>
            <p className="about-p">
            At Bye Bye Junk, we specialize in delivering fast, efficient, and reliable junk removal services for both residential and commercial clients.
             Our experienced team understands the importance of keeping your space clutter-free and stress-free.
              We are dedicated to providing hassle-free solutions, whether you need to clear out a single room, an entire property, or require regular pickups.
               As a small business, we offer personalized service with individualized attention tailored to your specific needs. 
               We ensure quick and cost-effective solutions, with transparent pricing and no hidden fees. 
               Our commitment to eco-friendly practices means we prioritize recycling and donating items whenever possible, supporting responsible disposal.
                Our team is professional, friendly, and ready to handle jobs of all sizes with dedication and a smile. 
                Experience the difference of personalized, reliable service with a focus on customer satisfaction and eco-conscious practices.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Home;
